























































































































































































import TestList from '@/components/TestList.vue';
import FilterPicker from '@/components/FilterPicker.vue';
import RightPanel from '@/components/RightPanel.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readTestSummary, readTraces } from '@/store/tests/getters';
import { readToken } from '@/store/main/getters';
import { dispatchGetTestSummary, dispatchGetTraces, dispatchMakeArchive } from '@/store/tests/actions';
import { commitSetTestSummary } from '@/store/tests/mutations';
import { ITestResult, IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { readCreatingArchive } from '@/store/main/getters';
import { commitSetCreatingArchive } from '@/store/main/mutations';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';

interface IReading {
  name: string;
  value: string;
}

interface IAnalyteSet {
  assay_name: string;
  fields: string[];
}

interface IReadingSet {
  assay_name: string;
  fields: string[];
}

// instrument, module, cart, gx_username, status, result, start_date, reagent_lot, assay, upload_date

@Component({
  components: {
    TestList,
    FilterPicker,
    RightPanel,
  },
})
export default class Tests extends Vue {

  public analyteHeaders: Array<{ [key in 'text' | 'value']: string }> = [
        { text: 'Name', value: 'name'},
        { text: 'PCR', value: 'is_pcr'},
        { text: 'Melt', value: 'is_melt_target'},
        { text: 'Control', value: 'is_control'},
        { text: 'Probe', value: 'is_probe'},
      ];

  public readingHeaders: Array<{ [key in 'text' | 'value']: string }> = [
        { text: 'Reading', value: 'name'},
      ];

  public selectedTestsHeaders: object[] = [
        { text: 'Sample', value: 'sample_id', groupable: false },
        { text: 'Assay Name', value: 'assay_name', groupable: true },
        { text: '', value: 'id', groupable: false },
      ];

  public selectedFilters: IFilter[] = [];


  public groupBy = 'PER_ASSAY';
  public showGraph: boolean = false;
  public selectedAssays: IAssay[] = [];
  public selectedAnalytes: { [key: string]: IAnalyte[] } = {};
  public selectedReadings: { [key: string]: IReading[] } = {};

  public defaultReadings: IReading[] = [{name: 'CT', value: 'ct'}, {name: 'Endpoint', value: 'endpoint'}, {name: 'PrbChk 2', value: 'pc2'}];
  public defaultMeltReadings: IReading[] = [{name: 'Peak Height', value: 'peak_height'}, {name: 'Peak Temp.', value: 'peak_temp'}];

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };

  public selectedTests: ITestResult[] = [];


  get selectedTestIds() {
    return this.selectedTests.map((t) => t.test_id as number);
  }

  get selectedTestResultIds() {
    return this.selectedTests.map((t) => t.id as number);
  }

  get selectedAssayIds() {
    return [...new Set(this.selectedTests.map((t) => t.assay_id as number))];
  }

  get testSummary() {
    return readTestSummary(this.$store);
  }

  get traces() {
    return readTraces(this.$store);
  }

  get downloadDisabled() {

    const creating = readCreatingArchive(this.$store);
    // if selectedTestResultIds in currentArchiveTasks

    const SwVersions = new Set();
    for (const tr of this.selectedTests) {
      SwVersions.add(tr.system_sw);
    }

    return creating || SwVersions.size !== 1 || !SwVersions.has('6.4');
  }

  /*get filters() {
    const filters: IFilter[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const filter of this.selectedFilters) {
      if (filter.value && filter.selectedOperator && filter.filterValue) {
        filters.push(
          {field: filter.value, operator: filter.selectedOperator, value: filter.filterValue },
        );
      }
    }
    return filters;
  }*/

  /*get filtersAsJson() {
    return JSON.stringify(this.filters);
  }*/

  get selectedAnalytesAsObject() {
    const analytes: IAnalyteSet[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const assay of this.selectedAssays) {
      analytes.push(
        {assay_name: assay.name, fields: this.selectedAnalytes[assay.name].map((an) => an.name)},
      );
    }
    return analytes;
  }

  get selectedReadingsAsObject() {
    const readings: IReadingSet[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const assay of this.selectedAssays) {
      readings.push(
        {assay_name: assay.name, fields: this.selectedReadings[assay.name].map((rd) => rd.value)},
      );
    }
    return readings;
  }

  public analyteHeadersForAssay(assay: IAssay) {
    if (assay.has_melt_analytes) {
      return this.analyteHeaders;
    } else {
      return this.analyteHeaders.filter((ah) => ah.value !== 'is_melt_target');
    }
  }

  public readingsForAssay(assay: IAssay) {
    if (assay.has_melt_analytes) {
      return this.defaultReadings.concat(this.defaultMeltReadings);
    } else {
      return this.defaultReadings;
    }
  }

  public clearSelectedTests() {
    this.selectedTests = [];
  }

  // For removing tests from the cart
  public deselectTest(testId) {
    this.selectedTests = this.selectedTests.filter((test: ITestResult) => test.id !== testId);
  }

  public getExcelSummary() {
    dispatchGetTestSummary(this.$store, {
      tests: this.selectedTestResultIds, format: 'EXCEL',
      analytes: this.selectedAnalytesAsObject, readings: this.selectedReadingsAsObject,
      groupBy: this.groupBy,
    });
  }

  public getHTMLSummary() {
    dispatchGetTestSummary(this.$store, {
      tests: this.selectedTestResultIds, format: 'HTML',
      analytes: this.selectedAnalytesAsObject, readings: this.selectedReadingsAsObject,
      groupBy: this.groupBy,
    });
  }

  public getNXXArchive() {
    commitSetCreatingArchive(this.$store, true);
    dispatchMakeArchive(this.$store, this.selectedTestResultIds);
  }

  public async getTraces(testIds: number[], kind: string, subtype: string = 'raw') {
    await dispatchGetTraces(this.$store, {tests: testIds, kind, subtype});
  }

  public async getPressure() {
    this.showGraph = true;

    await this.getTraces(this.selectedTestIds, 'pressure');
    const layout = {
        title: 'Pressure',
        xaxis: {
            title: 'Time (s)',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'PSI',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.traces, layout);
  }

  public tracesForSelectedAnalytes() {
    const returnTraces: ITrace[] = [];
    // const selectedAssays = this.selectedAssays;
    // const selectedAnalytes = this.selectedAnalytes;
    for (const assay of this.selectedAssays) {
      // console.log(assay);
      for (const analyte of assay.analytes) {
        // console.log(this.selectedAnalytes[assay.name]);
        if ([...this.selectedAnalytes[assay.name]].map((an) => an.name).includes(analyte.name)) {
          // console.log(analyte.name);
          const analytes = this.traces.filter((t) => t.name.includes(analyte.name));
          // console.log(analytes);
          returnTraces.push(...analytes);
        }
      }
    }
    return returnTraces;
  }

  public async getOptical() {
    this.showGraph = true;
    await this.getTraces(this.selectedTestIds, 'optical', 'primary');
    const layout = {
        title: 'Optical',
        xaxis: {
            title: 'Cycle',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'DFU',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.tracesForSelectedAnalytes(), layout);
  }

  public async getTemperature() {
    this.showGraph = true;
    await this.getTraces(this.selectedTestIds, 'temperature');
    const layout = {
        title: 'Temperature',
        xaxis: {
            title: 'Time (s)',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'C',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.traces, layout);
  }

  public removeSummary() {
    commitSetTestSummary(this.$store, '');
  }

  public hideGraph() {
    this.showGraph = false;
  }

  public mounted() {
    // dispatchGetTests(this.$store, this.selectedFilters);
  }

  @Watch('selectedTests')
  public async refreshAssays(newTests: ITestResult[], oldTests: ITestResult[]) {
    const currentAssayIds = new Set(newTests.map((t) => t.assay_id));
    if (newTests.length > 0 && currentAssayIds.size !== this.selectedAssays.length) {
      const previousAssayIds = new Set(oldTests.map((t) => t.assay_id));
      const newAssayIds = new Set(currentAssayIds);
      const removedAssayIds = new Set(previousAssayIds);
      for (const el of previousAssayIds) {
        newAssayIds.delete(el);
      }
      for (const el of currentAssayIds) {
        removedAssayIds.delete(el);
      }
      const assays = await api.getAssays(readToken(this.$store), this.selectedAssayIds);
      const newAssays = assays.data.filter(({id}) => newAssayIds.has(id));
      const removedAssays = this.selectedAssays.filter(({id}) => removedAssayIds.has(id));
      this.selectedAssays = assays.data;
      for (const a of newAssays) {
        if (this.selectedAnalytes[a.name] === undefined) {
          this.$set(this.selectedAnalytes, a.name, Array.from(a.analytes, (an) => an) );
        }
        if (this.selectedReadings[a.name] === undefined) {
          this.$set(this.selectedReadings, a.name, this.readingsForAssay(a) );
        }
      }
      for (const a of removedAssays) {
        this.$set(this.selectedAnalytes, a.name, []);
        this.$set(this.selectedReadings, a.name, []);
      }
    }
    if (newTests.length === 0) {
      this.selectedAssays = [];
    }
  }

}
